import { Box, Typography, Button } from "@mui/material";
import { Link } from 'react-router-dom';

import papelreal from "../../Components/Img/Productos/PH/Papel_Real.webp";
import botellagrande from "../../Components/Img/Productos/Botella_grande.webp";

import bolsasnacks from "../../Components/Img/Productos/Bolsa_snacks.webp";
import bolsajabon from "../../Components/Img/Productos/Bolsa_jabon.webp";


import bolsapaneton from "../../Components/Img/Productos/Bolsa_paneton.webp";
import bolsaarroz from "../../Components/Img/Productos/Bolsa_arroz.webp";

import postpitucan from "../../Components/Img/Productos/Post_Pitucan.webp";

const productos = [
    { nombre: "Etiqueta para bebidas", imagen: botellagrande },
    { nombre: "Bolsa para snacks", imagen: bolsasnacks },
    { nombre: "Bolsa para arroz", imagen: bolsaarroz },
    { nombre: "Bolsa para jabón", imagen: bolsajabon },
    { nombre: "Bolsa para panetón", imagen: bolsapaneton },
    { nombre: "Empaque papel higiénico", imagen: papelreal },
    { nombre: "Polipasacalle", imagen: postpitucan },

];

const Productos = () => (
    <Box sx={{ pt: 1.5, background: "linear-gradient(133deg, #336257 70%, #315A51 70%)", borderRadius: "35px", paddingBottom: "50px" }}>
        <Box sx={{ marginTop: "50px", textAlign: "center" }}>
            <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: "50px", sm: "50px", md: "60px", lg: "70px" }, color: "white" }}>
                Productos
            </Typography>
            <Link to="/productos" style={{ textDecoration: "none", color: "black" }}>
                <Button id="producto-button" className="plus-jakarta full-rounded button-productoinicio" sx={{ marginTop: "20px", fontSize: { xs: "10px", md: "14px" } }}>
                    Ver todos los productos

                </Button>

            </Link>

        </Box>

        <Box

            sx={{
                height: "400px",
                position: "relative",
                overflow: "hidden",
            }}
        >

            <Box sx={{ height: "400px", position: "relative", overflow: "hidden" }}>
                <Box className="slider">
                    <Box className="slide-track">
                        {productos.concat(productos).map((producto, index) => (
                            <Box
                                key={index}
                                sx={{
                                    borderRadius: "25px",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    height: "400px",
                                    position: "relative",
                                    display: "inline-block",
                                    width: "1000px"
                                }}
                            >
                                <Box
                                    sx={{
                                        marginTop: "60px",
                                        backgroundColor: "#FCFCFC",
                                        borderRadius: "25px",
                                        height: "300px",
                                        position: "relative",
                                        boxShadow: "0 5px 30px rgba(0, 0, 0, 0.2)",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#EFEFEF",
                                            borderRadius: "25px",
                                            padding: "10px",
                                            display: "inline-block",
                                            marginTop: "20px",
                                            marginLeft: "20px",
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <Typography className="plus-jakarta" sx={{ fontSize: "18px" }}>
                                            {producto.nombre}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "25px",
                                        }}
                                    >
                                        <img
                                            src={producto.imagen}
                                            alt={producto.nombre}
                                            style={{
                                                marginTop: "20px",
                                                width: "50%",
                                                height: "auto",
                                                transition: "transform 0.3s",
                                                zIndex: 1,
                                                position: "relative",
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>


    </Box>
);

export default Productos;