import { Box } from "@mui/material";
import logo1 from "../../Components/Img/Clientes/Real.webp"
import logo2 from "../../Components/Img/Clientes/Samplast.webp"
import logo3 from "../../Components/Img/Clientes/Gordito.webp"
import logo4 from "../../Components/Img/Clientes/Purina.webp"
import logo5 from "../../Components/Img/Clientes/Kuntur.webp"
import logo6 from "../../Components/Img/Clientes/Amable.webp"
import logo7 from "../../Components/Img/Clientes/IndustriasPapeleraM&M.webp"


const Clientes = () => {

    return (

        <Box

            sx={{
                height: "200px",
                position: "relative",
                overflow: "hidden",
            }}
        >


            <Box className="slider-cliente">
                <Box className="slide-track-cliente">
                    <div className="slide-cliente">
                        <img src={logo1} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo2} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo3} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo4} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo5} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo6} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo7} alt="" />
                    </div>

                    <div className="slide-cliente">
                        <img src={logo1} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo2} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo3} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo4} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo5} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo6} alt="" />
                    </div>
                    <div className="slide-cliente">
                        <img src={logo7} alt="" />
                    </div>

                </Box>
            </Box>
        </Box>


    );
}

export default Clientes;
