import NavBar from "../Components/NavBar";
import InicioProductos from "./Components/InicioProductos"
import ListaProductos from "./Components/ListaProductos";
import End from "../Inicio/Components/End"
import { Container, Grid, Box, Typography, Button } from "@mui/material";
import { Helmet } from 'react-helmet';

const ProductoItem = () => {
    return (
        <div>
            <NavBar />

            <Container maxWidth="xl" >
                <Helmet>
                    <title>Real Flexo</title>
                    <meta name="description" content="En Real Flexo, somos una empresa peruana orientada al desarrollo y fabricación de empaques flexibles. Nos especializamos en la impresión flexográfica de 10 colores, ofreciendo los más altos estándares de calidad en todos nuestros productos y servicios para satisfacer las necesidades de nuestros clientes." />
                </Helmet>
                <InicioProductos />
                <ListaProductos />
                <End />
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
                <a href="https://api.whatsapp.com/send?phone=946678535&text=Hola, me gustaria obtener más información" class="float" target="_blank">
                    <i class="fa fa-whatsapp my-float "></i>
                </a>
            </Container>

        </div>
    );
}

export default ProductoItem;
