import { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

// Importa las imágenes
import papelreal from "../../Components/Img/Productos/PH/Papel_Real.webp";
import papelmargarita from "../../Components/Img/Productos/PH/Papel_Margarita.webp";
import papelmargarita2 from "../../Components/Img/Productos/PH/Papel_Margarita2.webp";
import papelrango from "../../Components/Img/Productos/PH/Papel_Rango.webp";
import papellascasuarinas from "../../Components/Img/Productos/PH/Papel_LasCasuarinas.webp";
import papelricopel from "../../Components/Img/Productos/PH/Papel_Ricopel.webp";
import papelamable from "../../Components/Img/Productos/PH/Papel_Amable.webp";
import papelsamplast from "../../Components/Img/Productos/PH/Papel_Samplast.webp";
import papelkristel from "../../Components/Img/Productos/PH/Papel_Kristel.webp";
import papelellxs from "../../Components/Img/Productos/PH/Papel_Ellxs.webp";

import postpitucan from "../../Components/Img/Productos/Post_Pitucan.webp";
import postpitukats from "../../Components/Img/Productos/Post_Pitukats.webp";
import botellagrande from "../../Components/Img/Productos/Botella_grande.webp";
import botellagrande2 from "../../Components/Img/Productos/Botella_grande2.webp";
import botellaaceite from "../../Components/Img/Productos/Botella_aceite.webp";
import botellavidrio from "../../Components/Img/Productos/Botella_vidrio.webp";
import botellaplastico from "../../Components/Img/Productos/Botella_plastico.webp";
import botellamer from "../../Components/Img/Productos/Botella_mer.webp";
import bolsagrande from "../../Components/Img/Productos/Bolsa_grande.webp";
import bolsagrande2 from "../../Components/Img/Productos/Bolsa_grande2.webp";
import bolsasnacks from "../../Components/Img/Productos/Bolsa_snacks.webp";
import bolsasobre from "../../Components/Img/Productos/Bolsa_sobre.webp";
import bolsajabon from "../../Components/Img/Productos/Bolsa_jabon.webp";
import bolsagalleta2 from "../../Components/Img/Productos/Bolsa_galleta2.webp";
import bolsagranola from "../../Components/Img/Productos/Bolsa_granola.webp";
import bolsaketchup from "../../Components/Img/Productos/Bolsa_ketchup.webp";
import bolsachupetines from "../../Components/Img/Productos/Bolsa_chupetines.webp";
import bolsahamburguesa from "../../Components/Img/Productos/Bolsa_hamburguesa.webp";
import bolsapaneton from "../../Components/Img/Productos/Bolsa_paneton.webp";
import bolsaarroz from "../../Components/Img/Productos/Bolsa_arroz.webp";


const ListaProductos = () => {
    const [tipoProducto, setTipoProducto] = useState("bolsas");
    const [currentIndex, setCurrentIndex] = useState(0);

    const bolsas = [
        { nombre: "Bolsa para café", imagen: bolsagrande },
        { nombre: "Bolsa para alimento de mascotas", imagen: bolsagrande2 },
        { nombre: "Bolsa para snacks", imagen: bolsasnacks },
        { nombre: "Bolsa para jabón", imagen: bolsajabon },
        { nombre: "Bolsa para galletas", imagen: bolsagalleta2 },
        { nombre: "Bolsa para chocolate", imagen: bolsasobre },
        { nombre: "Bolsa para granola", imagen: bolsagranola },
        { nombre: "Bolsa para cremas", imagen: bolsaketchup },
        { nombre: "Bolsa para chupetes", imagen: bolsachupetines },
        { nombre: "Bolsa para hamburguesas", imagen: bolsahamburguesa },
        { nombre: "Bolsa para panetón", imagen: bolsapaneton },
        { nombre: "Bolsa para arroz", imagen: bolsaarroz },
    ];

    const etiquetas = [
        { nombre: "Etiqueta para bebidas", imagen: botellagrande },
        { nombre: "Etiqueta para bebidas", imagen: botellagrande2 },
        { nombre: "Etiqueta para bebidas", imagen: botellaplastico },
        { nombre: "Etiqueta para aceites", imagen: botellaaceite },
        { nombre: "Etiqueta para conservas", imagen: botellavidrio },
        { nombre: "Etiqueta para conservas", imagen: botellamer },
    ];

    const comercial = [
        { nombre: "Empaque papel higiénico Garza Real", imagen: papelreal },
        { nombre: "Empaque papel higiénico Margarita", imagen: papelmargarita },
        { nombre: "Empaque papel higiénico Margarita", imagen: papelmargarita2 },
        { nombre: "Empaque papel higiénico Ragno", imagen: papelrango },
        { nombre: "Empaque papel higiénico Las Casuarinas", imagen: papellascasuarinas },
        { nombre: "Empaque papel higiénico Ricopel", imagen: papelricopel },
        { nombre: "Empaque papel higiénico Ellx's", imagen: papelellxs },
        { nombre: "Empaque papel higiénico Amable", imagen: papelamable },
        { nombre: "Empaque papel higiénico Kristel", imagen: papelkristel },
        { nombre: "Empaque papel higiénico Samplast", imagen: papelsamplast },
        { nombre: "Polipasacalle", imagen: postpitukats },
        { nombre: "Polipasacalle", imagen: postpitucan },
    ];

    const productos = {
        bolsas: bolsas,
        etiquetas: etiquetas,
        comercial: comercial,
    };

    useEffect(() => {
        setCurrentIndex(0);
    }, [tipoProducto]);

    const handleClick = (tipo) => {
        setTipoProducto(tipo);
    };

    const groupedItems = productos[tipoProducto].reduce((acc, item, index) => {
        const groupIndex = Math.floor(index / 6);
        if (!acc[groupIndex]) {
            acc[groupIndex] = [];
        }
        acc[groupIndex].push(item);
        return acc;
    }, []);

    return (
        <Box sx={{ p: 1.5 }}>
            <Box sx={{ marginTop: "50px", textAlign: "center" }}>
                <Typography className="plus-jakarta-bold" sx={{ fontSize: "50px", color: "black", marginBottom: "40px" }}>
                    Coloca tu diseño
                </Typography>
                <Box sx={{
                    backgroundColor: "#FCFCFC", borderRadius: "35px", width: "400px", margin: "auto", '@media (max-width: 1200px)': {
                        width: "330px"
                    }
                }}>
                    <Button
                        id="producto-button"
                        className="plus-jakarta"
                        sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginRight: "13%",
                            backgroundColor: tipoProducto === "bolsas" ? "#EFEFEF !important" : undefined,
                            '@media (max-width: 1200px)': {
                                marginRight: "10px",
                            }
                        }}
                        onClick={() => handleClick("bolsas")}
                    >
                        Bolsas
                    </Button>
                    <Button
                        id="producto-button"
                        className="plus-jakarta"
                        sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginRight: "13%",
                            backgroundColor: tipoProducto === "etiquetas" ? "#EFEFEF !important" : undefined,
                            '@media (max-width: 1200px)': {
                                marginRight: "10px",
                            }
                        }}
                        onClick={() => handleClick("etiquetas")}
                    >
                        Etiquetas
                    </Button>
                    <Button
                        id="producto-button"
                        className="plus-jakarta"
                        sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: tipoProducto === "comercial" ? "#EFEFEF !important" : undefined,

                        }}
                        onClick={() => handleClick("comercial")}
                    >
                        Línea Comercial
                    </Button>
                </Box>
            </Box>

            <Carousel
                index={currentIndex}
                onChange={(newIndex) => setCurrentIndex(newIndex)}
            >
                {groupedItems.map((group, i) => (
                    <Box key={i} sx={{ padding: 2 }}>
                        <Grid container spacing={2}>
                            {group.map((producto, j) => (
                                <Grid item xs={12} md={4} xl={4} key={j}>
                                    <Paper sx={{ borderRadius: "25px", height: "100%", elevation: 0, backgroundColor: "#f5f5f5" }}>
                                        <Box sx={{
                                            backgroundColor: "#FCFCFC",
                                            borderRadius: "25px",
                                            position: "relative",
                                            height: "350px",
                                            overflow: "hidden"

                                        }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: "#EFEFEF",
                                                    borderRadius: "25px",
                                                    padding: "10px",
                                                    display: "inline-block",
                                                    marginTop: "20px",
                                                    marginLeft: "20px",
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <Typography className="plus-jakarta" sx={{
                                                    fontSize: "18px", '@media (max-width: 500px)': {
                                                        fontSize: '15px'
                                                    }
                                                }}>
                                                    {producto.nombre}
                                                </Typography>

                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    visibility: 'hidden',
                                                    width: '100%',
                                                    height: '100%',
                                                    top: 0,
                                                    left: 0,
                                                }}
                                                className={`loader-container loader-${i}-${j}`}
                                            >
                                                <div className="loader">
                                                    <div className="circle"></div>
                                                    <div className="circle"></div>
                                                    <div className="circle"></div>
                                                    <div className="circle"></div>
                                                </div>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <img
                                                    src={producto.imagen}
                                                    alt={producto.nombre}
                                                    style={{ maxWidth: "100%", maxHeight: "250px" }}
                                                    onLoad={(e) => {
                                                        document.querySelector(`.loader-${i}-${j}`).style.visibility = 'hidden'; // Oculta el loader cuando la imagen se carga
                                                        e.target.style.display = 'block';
                                                    }}
                                                />

                                            </Box>

                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))}
            </Carousel>
        </Box>
    );
}

export default ListaProductos;
