import NavBar from "../Components/NavBar";
import End from "../Inicio/Components/End"
import { Container, Grid, Box, Typography } from "@mui/material";

import extrusion from "../Components/Img/Proceso/Extrusion.webp";
import extrusionvideo from "../Components/Img/Proceso/Extrusion-Video.mp4";
import impresion from "../Components/Img/Proceso/Impresion.webp";
import impresionvideo from "../Components/Img/Proceso/Impresion-Video.mp4";
import laminado from "../Components/Img/Proceso/Laminado.webp";
import laminado2 from "../Components/Img/Proceso/Laminado2.webp";
import laminadovideo from "../Components/Img/Proceso/Laminado-Video.mp4";

import sellado from "../Components/Img/Proceso/Sellado.webp";
import selladovideo from "../Components/Img/Proceso/Sellado-Video.mp4";
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Proceso = () => {
    useEffect(() => {
        const revealElements = document.querySelectorAll('.proceso');

        const revealOnScroll = () => {
            revealElements.forEach(el => {
                const windowHeight = window.innerHeight;
                const elementTop = el.getBoundingClientRect().top;
                const elementVisible = 150;

                if (elementTop < windowHeight - elementVisible) {
                    el.classList.add('active');
                } else {
                    el.classList.remove('active');
                }
            });
        };

        window.addEventListener('scroll', revealOnScroll);

        // Limpia el listener cuando el componente se desmonte
        return () => window.removeEventListener('scroll', revealOnScroll);
    }, []);


    return (
        <div>
            <NavBar />
            <Helmet>
                <title>Real Flexo</title>
                <meta name="description" content="En Real Flexo, somos una empresa peruana orientada al desarrollo y fabricación de empaques flexibles. Nos especializamos en la impresión flexográfica de 10 colores, ofreciendo los más altos estándares de calidad en todos nuestros productos y servicios para satisfacer las necesidades de nuestros clientes." />            </Helmet>
            <Container maxWidth="xl" >
                <Box sx={{ textAlign: 'center', marginBottom: '40px' }}>
                    <Typography className="plus-jakarta-bold"
                        sx={{
                            fontSize: { xs: "40px", sm: "50px", md: "60px", lg: "70px" },
                            color: "black",
                            maxWidth: "100%",
                            marginBottom: { xs: "30px", md: "50px" },
                            textAlign: { xs: "center" }
                        }}>
                        Nuestro Proceso
                    </Typography>
                    <Typography className="plus-jakarta-bold" sx={{ color: 'gray' }}>
                        Contamos de tecnología avanzada y un equipo de profesionales para ofrecerte la mejor calidad en nuestros productos.
                    </Typography>
                </Box>

                <Box >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <Box sx={{
                                backgroundColor: "#FCFCFC",
                                borderRadius: '25px',
                                padding: '40px',
                                height: { xs: '350px', md: '600px' },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                    padding: '15px',
                                },
                            }}>
                                <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: "30px", lg: "40px" }, color: 'black', marginBottom: { xs: "5px", lg: "15px" } }}>
                                    Extrusión
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12px", lg: "16px" },
                                    color: '#494949', textAlign: 'justify', marginBottom: "10px"
                                }}>

                                    Contamos con máquinas extrusoras de Polietileno Alta y Baja Densidad y de
                                    Polipropileno. También una co extrusora de tres capas de última tecnología el
                                    cual asegura nuestra calidad en laminas que aseguran una buena sellabilidad
                                    y resistencia en nuestros empaques en monocapas, laminados y trilaminados.
                                </Typography>

                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    1.  Láminas de color.
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>
                                    2. Láminas cristales.
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    3. Mangas cerradas.
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    4.  Láminas resmas.
                                </Typography>

                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12px", lg: "16px" },
                                    color: '#494949', textAlign: 'justify', marginTop: "10px"
                                }}>
                                    Importamos resinas de buena calidad y utilizamos aditivos según requiera el
                                    empaque del producto.
                                </Typography>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box
                                sx={{
                                    backgroundColor: "#FCFCFC",
                                    borderRadius: '25px',
                                    padding: '40px',
                                    height: { xs: '350px', md: '600px' },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                    '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                        padding: '10px',
                                        paddingTop: "20px",
                                    },
                                }}>
                                <Grid container spacing={1}>
                                    <Grid xs={7} >
                                        {/* Contenedor de la imagen */}
                                        <Box
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                '& img, & video': {

                                                    width: '440px',
                                                    maxHeight: '520px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                    objectPosition: 'center',
                                                    marginLeft: "10px"


                                                },
                                                '@media (max-width: 1400px)': { // Cambia 600px según el punto de quiebre deseado
                                                    '& img, & video': {
                                                        maxHeight: '395px',
                                                        borderRadius: '15px',
                                                        transition: 'transform 0.7s ease',
                                                        marginRight: "20px",
                                                        objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                        objectPosition: 'center',
                                                    },
                                                },
                                                '@media (max-width: 900px)': { // Cambia 600px según el punto de quiebre deseado
                                                    '& img, & video': {
                                                        maxHeight: '295px',
                                                        borderRadius: '15px',
                                                        transition: 'transform 0.7s ease',
                                                        marginRight: "20px",
                                                        objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                        objectPosition: 'center',
                                                    },
                                                },
                                                '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                                    '& img, & video': {
                                                        maxHeight: '275px',
                                                        borderRadius: '15px',
                                                        transition: 'transform 0.7s ease',
                                                        marginRight: "20px",
                                                        objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                        objectPosition: 'center',

                                                    },
                                                },
                                            }}

                                        >
                                            <img src={extrusion} />

                                        </Box>
                                    </Grid>
                                    {/* Contenedor del video */}
                                    <Grid xs={5} >
                                        <Box sx={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            '& img, & video': {

                                                maxWidth: '445px',
                                                maxHeight: '520px',
                                                borderRadius: '15px',
                                                transition: 'transform 0.7s ease',
                                            },
                                            '@media (max-width: 1400px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    maxHeight: '393px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginLeft: "20px"
                                                },
                                            },
                                            '@media (max-width: 900px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    maxHeight: '295px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginLeft: "20px"
                                                },
                                            },
                                            '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    maxHeight: '275px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginRight: "32px",
                                                    objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                    objectPosition: 'center',

                                                },
                                            },
                                        }}>
                                            <video
                                                src={extrusionvideo} // Asegúrate de definir la URL del video
                                                controls
                                            // style={{
                                            //     width: "445px",
                                            //     maxWidth: "300px", // Ajusta el tamaño máximo si es necesario
                                            //     height: "520px",
                                            //     borderRadius: "15px",
                                            // }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>


                        </Grid>
                    </Grid>
                </Box>
                <Box className="proceso" sx={{ marginTop: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7} sx={{ order: { xs: 2, md: 1 } }}>
                            <Box
                                sx={{
                                    backgroundColor: "#FCFCFC",
                                    borderRadius: '25px',
                                    padding: '40px',
                                    height: { xs: '350px', md: '600px' },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                    '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                        padding: '10px',
                                        paddingTop: "20px",
                                    },
                                }}>
                                <Grid container spacing={1}>
                                    <Grid xs={7} >
                                        {/* Contenedor de la imagen */}
                                        <Box
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                '& img, & video': {

                                                    width: '440px',
                                                    maxHeight: '520px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                    objectPosition: 'center',
                                                    marginLeft: "10px"

                                                },
                                                '@media (max-width: 1400px)': { // Cambia 600px según el punto de quiebre deseado
                                                    '& img, & video': {
                                                        maxWidth: '290px',
                                                        borderRadius: '15px',
                                                        transition: 'transform 0.7s ease',
                                                        marginRight: "20px",
                                                        objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                        objectPosition: 'center',
                                                    },
                                                },
                                                '@media (max-width: 900px)': { // Cambia 600px según el punto de quiebre deseado
                                                    '& img, & video': {
                                                        maxHeight: '295px',
                                                        borderRadius: '15px',
                                                        transition: 'transform 0.7s ease',
                                                        marginRight: "20px",
                                                        objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                        objectPosition: 'center',
                                                    },
                                                },
                                                '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                                    '& img, & video': {
                                                        height: '275px',
                                                        width: "180px",
                                                        borderRadius: '15px',
                                                        transition: 'transform 0.7s ease',
                                                        marginRight: "20px",


                                                    },
                                                },
                                            }}

                                        >
                                            <img src={impresion} />

                                        </Box>
                                    </Grid>
                                    {/* Contenedor del video */}
                                    <Grid xs={5} >
                                        <Box sx={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            '& img, & video': {

                                                maxWidth: '445px',
                                                maxHeight: '520px',
                                                borderRadius: '15px',
                                                transition: 'transform 0.7s ease',
                                            },
                                            '@media (max-width: 1400px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    maxHeight: '393px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginLeft: "20px"
                                                },
                                            },
                                            '@media (max-width: 900px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    maxHeight: '295px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginLeft: "20px"
                                                },
                                            },
                                            '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    maxHeight: '275px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginRight: "32px",
                                                    objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                    objectPosition: 'center',

                                                },
                                            },
                                        }}>
                                            <video
                                                src={impresionvideo} // Asegúrate de definir la URL del video
                                                controls
                                            // style={{
                                            //     width: "445px",
                                            //     maxWidth: "300px", // Ajusta el tamaño máximo si es necesario
                                            //     height: "520px",
                                            //     borderRadius: "15px",
                                            // }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={5} sx={{ order: { xs: 1, md: 2 } }}>
                            <Box sx={{
                                backgroundColor: "#FCFCFC",
                                borderRadius: '25px',
                                padding: '40px',
                                height: { xs: '350px', md: '600px' },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                    padding: '15px',
                                },
                            }}>
                                <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: "30px", lg: "40px" }, color: 'black', marginBottom: { xs: "5px", lg: "15px" } }}>
                                    Impresión
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12px", lg: "16px" },
                                    color: '#494949', textAlign: 'justify', marginBottom: "10px",
                                    '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                        fontSize: '10.5px',
                                    },
                                }}>

                                    Nuestra tecnología de impresión flexográfica, de origen europeo, es capaz de imprimir entre cuatro y diez colores con una resolución de hasta 150 LPI, posicionándola entre las mejores del país. Este avanzado equipo no solo garantiza resultados de alta calidad, sino que también destaca por su gran eficiencia. Equipado con sistemas automáticos de control de última generación, ofrece una supervisión continua tanto del color como del registro, permitiendo la detección temprana de cualquier irregularidad durante el proceso. Esto asegura una impresión precisa y consistente en cada proyecto.</Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    1.  Diseños en full color.
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>
                                    2. Diseños con efectos de alta luminosidad y degradados.
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    3. Diseños sobre una variedad de sustratos.
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    4.  Diseños adaptados para procesos de bilaminado y monocapas.
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>
                </Box >
                <Box className="proceso" sx={{ marginTop: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} >
                            <Box sx={{
                                backgroundColor: "#FCFCFC",
                                borderRadius: '25px',
                                padding: '40px',
                                height: { xs: '350px', md: '600px' },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                    padding: '15px',
                                },
                            }}>
                                <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: "30px", lg: "40px" }, color: 'black', marginBottom: { xs: "5px", lg: "15px" } }}>
                                    Laminado
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12px", lg: "16px" },
                                    color: '#494949', textAlign: 'justify', marginBottom: "10px",
                                    '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                        fontSize: '10.5px',
                                    },
                                }}>

                                    Nuestra laminadora Nordmeccanica Duplex Combi Linear se especializa en el
                                    procesamiento de bilaminados y trilaminados con y sin solvente.
                                    Incorpora un avanzado sistema de tratamiento en línea que ajusta y refuerza la
                                    tensión superficial de las películas o láminas, dando una buena fuerza de
                                    laminación.
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    1. Láminas de polipropileno
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>
                                    2. Láminas de poliéster
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    3. Láminas de nylon
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    4. Láminas de polietileno
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12.5px", lg: "16.5px" },
                                    color: 'black'
                                }}>

                                    5. Foil de aluminio

                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: "12px", lg: "16px" },
                                    color: '#494949', textAlign: 'justify', marginTop: "10px",
                                    '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                        fontSize: '10.5px',
                                    },
                                }}>

                                    Nuestro sistema de laminación con solvente permite una fuerza de laminación
                                    100% garantizada en materiales delicados como el foil aluminio y BOPPA.
                                </Typography>

                            </Box>

                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box
                                sx={{
                                    backgroundColor: "#FCFCFC",
                                    borderRadius: '25px',
                                    padding: '40px',
                                    height: { xs: '350px', md: '600px' },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                    '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                        padding: '10px',
                                        paddingTop: "20px",
                                    },
                                }}>

                                <Box sx={{
                                    flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
                                    '& img, & video': {
                                        width: '300px',
                                        height: "522px",
                                        borderRadius: '15px',
                                        transition: 'transform 0.7s ease',


                                    },
                                    '@media (max-width: 1400px)': { // Cambia 600px según el punto de quiebre deseado
                                        '& img, & video': {
                                            width: '270px',
                                            height: '400px',
                                            borderRadius: '15px',
                                            transition: 'transform 0.7s ease',
                                        },
                                    },
                                    '@media (max-width: 900px)': { // Cambia 600px según el punto de quiebre deseado
                                        '& img, & video': {
                                            width: '225px',
                                            height: '300px',
                                            borderRadius: '15px',
                                            transition: 'transform 0.7s ease',
                                            marginRight: "20px"

                                        },
                                    },
                                    '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                        '& img, & video': {
                                            height: '275px',
                                            width: "170px",
                                            borderRadius: '15px',
                                            transition: 'transform 0.7s ease',
                                            objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                            objectPosition: 'center',
                                            marginLeft: "40px",
                                            marginRight: "5px",

                                        },
                                    },
                                }}>
                                    <img
                                        src={laminado2}

                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column", // Apila imagen y video verticalmente
                                        alignItems: "center",
                                        justifyContent: "center",

                                    }}>

                                    <Box
                                        sx={{
                                            flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: "20px",
                                            '& img, & video': {
                                                width: '440px',
                                                height: "250px",
                                                borderRadius: '15px',
                                                transition: 'transform 0.7s ease',
                                                objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                objectPosition: 'center',

                                            },
                                            '@media (max-width: 1400px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    width: '220px',
                                                    height: '255px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                    objectPosition: 'center',
                                                },
                                            },
                                            '@media (max-width: 900px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    width: '190px',
                                                    height: '170px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginRight: "20px",
                                                    objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                    objectPosition: 'center',

                                                },
                                            },
                                            '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    width: '170px',
                                                    height: '130px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                    objectPosition: 'center',
                                                    marginRight: "42px",

                                                },
                                            },
                                        }}>
                                        <img
                                            src={laminado}

                                        />
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: "15px",
                                        '& img, & video': {
                                            width: '440px',
                                            height: "250px",
                                            borderRadius: '15px',
                                            transition: 'transform 0.7s ease',

                                        },
                                        '@media (max-width: 1400px)': { // Cambia 600px según el punto de quiebre deseado
                                            '& img, & video': {
                                                width: '220px',
                                                height: '125px',
                                                borderRadius: '15px',
                                                transition: 'transform 0.7s ease',
                                            },
                                        },
                                        '@media (max-width: 900px)': { // Cambia 600px según el punto de quiebre deseado
                                            '& img, & video': {
                                                width: '190px',
                                                height: '110px',
                                                borderRadius: '15px',
                                                transition: 'transform 0.7s ease',
                                                marginRight: "20px"
                                            },
                                        },
                                        '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                            '& img, & video': {
                                                width: '170px',
                                                height: '120px',
                                                borderRadius: '15px',
                                                transition: 'transform 0.7s ease',
                                                objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                objectPosition: 'center',
                                                marginRight: "42px",

                                            },
                                        },
                                    }}>
                                        <video
                                            src={laminadovideo}
                                            controls

                                        />
                                    </Box>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
                <Box className="proceso" sx={{ marginTop: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7} sx={{ order: { xs: 2, md: 1 } }}>
                            <Box
                                sx={{
                                    backgroundColor: "#FCFCFC",
                                    borderRadius: '25px',
                                    padding: '40px',
                                    height: { xs: '350px', md: '600px' },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                    '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                        padding: '10px',
                                        paddingTop: "20px",
                                    },
                                }}>
                                <Grid container spacing={1}>
                                    <Grid xs={7} >
                                        <Box
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                '& img, & video': {

                                                    width: '440px',
                                                    maxHeight: '520px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                    marginLeft: "10px"


                                                },
                                                '@media (max-width: 1400px)': {
                                                    '& img, & video': {
                                                        maxHeight: '395px',

                                                        borderRadius: '15px',
                                                        transition: 'transform 0.7s ease',
                                                        marginRight: "20px",
                                                        objectFit: 'cover',
                                                        objectPosition: 'center',
                                                    },
                                                },
                                                '@media (max-width: 900px)': {
                                                    '& img, & video': {
                                                        maxHeight: '295px',
                                                        borderRadius: '15px',
                                                        transition: 'transform 0.7s ease',
                                                        marginRight: "20px",
                                                        objectFit: 'cover',
                                                        objectPosition: 'center',
                                                    },
                                                },
                                                '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                                    '& img, & video': {
                                                        maxHeight: '275px',
                                                        borderRadius: '15px',
                                                        transition: 'transform 0.7s ease',
                                                        objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                        objectPosition: 'center',

                                                    },
                                                },
                                            }}

                                        >
                                            <img src={sellado} />

                                        </Box>
                                    </Grid>
                                    {/* Contenedor del video */}
                                    <Grid xs={5} >
                                        <Box sx={{
                                            flex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            '& img, & video': {

                                                maxWidth: '445px',
                                                maxHeight: '520px',
                                                borderRadius: '15px',
                                                transition: 'transform 0.7s ease',

                                            },
                                            '@media (max-width: 1400px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    maxHeight: '393px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginLeft: "20px"
                                                },
                                            },
                                            '@media (max-width: 900px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    maxHeight: '295px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginLeft: "20px"
                                                },
                                            },
                                            '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                                '& img, & video': {
                                                    maxHeight: '275px',
                                                    borderRadius: '15px',
                                                    transition: 'transform 0.7s ease',
                                                    marginRight: "32px",
                                                    objectFit: 'cover', // Esto asegura que la imagen mantenga sus proporciones
                                                    objectPosition: 'center',

                                                },
                                            },
                                        }}>
                                            <video
                                                src={selladovideo} // Asegúrate de definir la URL del video
                                                controls
                                            // style={{
                                            //     width: "445px",
                                            //     maxWidth: "300px", // Ajusta el tamaño máximo si es necesario
                                            //     height: "520px",
                                            //     borderRadius: "15px",
                                            // }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={5} sx={{ order: { xs: 1, md: 2 } }}>
                            <Box sx={{
                                backgroundColor: "#FCFCFC",
                                borderRadius: '25px',
                                padding: '40px',
                                height: { xs: '350px', md: '600px' },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                                '@media (max-width: 500px)': { // Cambia 600px según el punto de quiebre deseado
                                    padding: '15px',
                                },
                            }}>
                                <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '30px', md: '40px' }, color: 'black', marginBottom: { xs: "5px", lg: "15px" } }}>
                                    Sellado
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: '12px', md: '16px' },
                                    color: '#494949', textAlign: 'justify', marginBottom: "10px"
                                }}>

                                    Disponemos de máquinas de última tecnología para fabricar una variedad de
                                    bolsas con diferentes estructuras, tales como:
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: '12.5px', md: '16.5px' },
                                    color: 'black'
                                }}>

                                    1. Bolsas tipo doypack con zipper y troqueles personalizados.
                                </Typography>

                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: '12.5px', md: '16.5px' },
                                    color: 'black'
                                }}>
                                    2. Bolsas con tres sellos, zipper y troquel.
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: '12.5px', md: '16.5px' },
                                    color: 'black'
                                }}>

                                    3. Bolsas con sello K, zipper y troquel.
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: '12.5px', md: '16.5px' },
                                    color: 'black'
                                }}>
                                    4. Bolsas con sello lateral y troquel.

                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: '12.5px', md: '16.5px' },
                                    color: 'black'
                                }}>

                                    5. Bolsas con sello transversal o sello T, con opción de incluir zipper y troquel.

                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{
                                    fontSize: { xs: '12px', md: '16px' },
                                    color: '#494949', textAlign: 'justify', marginTop: "10px"
                                }}>

                                    Estas máquinas permiten un control automático de sus condiciones, lo que incrementa nuestra productividad y garantiza empaques de alta calidad de manera eficiente.
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <End />

                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
                <a href="https://api.whatsapp.com/send?phone=946678535&text=Hola, me gustaria obtener más información" class="float" target="_blank">
                    <i class="fa fa-whatsapp my-float "></i>
                </a>
            </Container >

        </div >
    );
}

export default Proceso;
