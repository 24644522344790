import NavBar from "../Components/NavBar";
import PhoneIcon from '@mui/icons-material/Phone';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import End from "../Inicio/Components/End";
import { Container, Grid, Box, Typography, Button, TextField } from "@mui/material";
import { Helmet } from 'react-helmet';

const Contacto = () => {
    return (
        <div>
            <NavBar />
            <Helmet>
                <title>Real Flexo</title>
                <meta name="description" content="En Real Flexo, somos una empresa peruana orientada al desarrollo y fabricación de empaques flexibles. Nos especializamos en la impresión flexográfica de 10 colores, ofreciendo los más altos estándares de calidad en todos nuestros productos y servicios para satisfacer las necesidades de nuestros clientes." />
            </Helmet>
            <Container maxWidth="xl">
                <Box sx={{ textAlign: 'center', marginBottom: { xs: '20px', md: '40px' } }}>
                    <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '40px', md: '80px' }, color: 'black' }}>
                        Contacto
                    </Typography>
                </Box>

                <Box sx={{
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "#FCFCFC", borderRadius: '25px', padding: { xs: '20px', md: '40px' }, margin: { xs: '10px', md: '25px' }, height: { xs: 'auto', md: '600px' }, paddingTop: { xs: '30px', md: "70px" }
                }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6} sx={{ paddingRight: { md: "80px" }, paddingBottom: { xs: '20px', md: '0' } }}>
                            <Box sx={{ marginBottom: '20px', textAlign: { xs: 'center', md: 'left' } }}>
                                <Typography className="plus-jakarta" sx={{ fontSize: { xs: '24px', md: '40px' }, marginBottom: '20px' }}>
                                    Información
                                </Typography>
                                <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, marginBottom: '20px' }}>
                                    Real Flexo SAC respeta su privacidad y solo utilizará sus datos personales para responder a su consulta.
                                </Typography>
                            </Box>

                            <Box sx={{ marginBottom: '20px' }}>
                                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                                    <Grid item>
                                        <a href="tel:+51946678535" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <PhoneIcon sx={{ fontSize: '40px', marginRight: { xs: '5px', md: 'none' }, color: "black" }} />
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <Typography className="plus-jakarta-bold" sx={{ fontSize: '16px' }}>Celular</Typography>
                                        <a href="tel:+51946678535" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <Typography href="tel:+51946678535" className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold' }}>
                                                (+51) 946-678-535
                                            </Typography>
                                        </a>
                                        <a href="tel:+51967251294" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <Typography href="tel:+51967251294" className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold' }}>
                                                (+51) 967-251-294
                                            </Typography>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Box>

                            <hr />

                            <Box sx={{ marginBottom: '20px' }}>
                                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                                    <Grid item>
                                        <a href="mailto:ventas@realflexo.com.pe" style={{ textDecoration: 'none', color: 'inherit' }}>

                                            <MarkunreadIcon sx={{ fontSize: '40px', marginRight: { xs: '5px', md: 'none' }, color: "black" }} />
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <Typography className="plus-jakarta-bold" sx={{ fontSize: '16px' }}>Correo</Typography>
                                        <a href="mailto:ventas@realflexo.com.pe" style={{ textDecoration: 'none', color: 'inherit' }}>

                                            <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold' }}>
                                                ventas@realflexo.com.pe
                                            </Typography>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Box>

                            <hr />

                            <Box sx={{ marginBottom: '20px' }}>
                                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                                    <Grid item>
                                        <LocationOnIcon sx={{ fontSize: '40px', color: "black" }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography className="plus-jakarta-bold" sx={{ fontSize: '16px' }}>Dirección</Typography>
                                        <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold' }}>
                                            Los Proceres 913 - S.J.L - Lima - Perú
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <form action="https://formspree.io/f/manwjbbb" method="POST">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold' }}>
                                            Nombre completo
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name="Nombre"
                                            placeholder="Nombre completo"
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            sx={{ backgroundColor: "white", borderRadius: "25px", "& .MuiOutlinedInput-root": { borderRadius: "25px" } }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold' }}>
                                            Empresa
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name="Empresa"
                                            placeholder="Empresa"
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            sx={{ backgroundColor: "white", borderRadius: "25px", "& .MuiOutlinedInput-root": { borderRadius: "25px" } }}

                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold' }}>
                                            Email
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name="Email"
                                            placeholder="Email"
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            type="email"
                                            sx={{ backgroundColor: "white", borderRadius: "25px", "& .MuiOutlinedInput-root": { borderRadius: "25px" } }}

                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold' }}>
                                            Número de celular
                                        </Typography>
                                        <TextField
                                            name="Numero"
                                            fullWidth
                                            placeholder="Número de celular"
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            type="tel"
                                            sx={{ backgroundColor: "white", borderRadius: "25px", "& .MuiOutlinedInput-root": { borderRadius: "25px" } }}

                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: '16px', md: '20px' }, fontWeight: 'bold' }}>
                                            Mensaje
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            placeholder="Escribe tu mensaje aquí..."
                                            name="Mensaje"
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            sx={{ backgroundColor: "white", borderRadius: "25px", "& .MuiOutlinedInput-root": { borderRadius: "25px" } }}

                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            id="navbar-button"
                                            className="plus-jakarta-bold"
                                            sx={{ height: '56px', borderRadius: '28px' }}
                                            type="submit"
                                            required
                                        >
                                            Enviar mensaje
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Box>

                <End />

                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
                <a href="https://api.whatsapp.com/send?phone=946678535&text=Hola, me gustaria obtener más información" class="float" target="_blank">
                    <i class="fa fa-whatsapp my-float "></i>
                </a>
            </Container>
        </div>
    );
};

export default Contacto;
