import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import Inicio from './Views/Inicio/Inicio';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import 'moment-timezone';
import ProductoItem from './Views/Productos/Productos';
import Contacto from './Views/Contacto/Contacto';
import Proceso from './Views/Proceso/Proceso';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Inicio />
  },
  {
    path: "/productos",
    element: <ProductoItem />
  },
  {
    path: "/contacto",
    element: <Contacto />
  },
  {
    path: "/proceso",
    element: <Proceso />
  },


])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


