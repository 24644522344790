import { Container, Grid, Box, Typography, Button } from "@mui/material";
import logo from "./Img/logo.webp";
import smallLogo from "./Img/smallLogo.webp";

import { Link } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import MarkunreadIcon from '@mui/icons-material/Markunread';

const NavBar = () => {

    return (
        <div>

            <Container maxWidth="xl" sx={{ marginTop: "20px" }}>
                <Box sx={{ height: "120px", width: "100%", backgroundColor: "rgba(255, 255, 255, 0)", alignItems: "center", padding: { md: "0 20px" } }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {/* Logo para pantallas grandes */}
                        <Box sx={{
                            display: {
                                xs: 'none', sm: "none", md: 'block', '@media (max-width: 1100px)': {
                                    display: 'none',
                                }
                            }
                        }
                        } >
                            <Link to="/">
                                <img src={logo} alt="Logo" style={{ height: "50px", width: "auto" }} />
                            </Link>
                        </Box>

                        {/* Logo para pantallas pequeñas */}
                        <Box sx={{
                            display: {
                                xs: 'block', sm: "block", md: 'none',
                                '@media (max-width: 1100px)': {
                                    display: 'block',
                                }

                            }, marginRight: "5px"
                        }}>
                            <img src={smallLogo} alt="Small Logo" style={{ height: "50px", width: "auto" }} />
                        </Box>

                        <Box>
                            <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                                <Grid item>
                                    <a href="tel:+51946678535" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <PhoneIcon sx={{
                                            fontSize: '28px', marginRight: { xs: '5px', md: 'none' }, color: "black", '@media (max-width: 500px)': {
                                                display: 'none',
                                            }
                                        }} />
                                    </a>
                                </Grid>
                                <Grid item sx={{ display: { xs: 'none', md: 'block' }, marginRight: "10px" }}>
                                    <a href="tel:+51946678535" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <Typography href="tel:+51946678535" className="plus-jakarta-bold" sx={{
                                            fontSize: {
                                                md: '14px'
                                                , '@media (max-width: 1000px)': {
                                                    fontSize: '12px',
                                                }
                                                , '@media (max-width: 950px)': {
                                                    fontSize: '11px',
                                                }
                                            }

                                            , fontWeight: 'bold'
                                        }}>
                                            (+51) 946-678-535
                                        </Typography>
                                    </a>
                                </Grid>

                                <Grid item >
                                    <a href="mailto:ventas@realflexo.com.pe" style={{ textDecoration: 'none', color: 'inherit' }}>

                                        <MarkunreadIcon sx={{
                                            fontSize: '28px', marginRight: { xs: '5px', md: 'none' }, color: "black", '@media (max-width: 500px)': {
                                                display: 'none',
                                            }
                                        }} />
                                    </a>
                                </Grid>
                                <Grid item sx={{
                                    display: {
                                        xs: 'none', md: 'block'
                                    }
                                    , '@media (max-width: 1000px)': {
                                        fontSize: '10px',
                                    }
                                }}>
                                    <a href="mailto:ventas@realflexo.com.pe" style={{ textDecoration: 'none', color: 'inherit' }}>

                                        <Typography className="plus-jakarta-bold" sx={{
                                            fontSize: {
                                                md: '15px'
                                                , '@media (max-width: 1000px)': {
                                                    fontSize: '13px',
                                                }
                                                , '@media (max-width: 950px)': {
                                                    fontSize: '12px',
                                                }
                                            }

                                            , fontWeight: 'bold'
                                        }}>ventas@realflexo.com.pe</Typography>
                                    </a>

                                </Grid>
                            </Grid>

                        </Box>


                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
                                <Typography className="plus-jakarta navbar-letra menu__link" sx={{
                                    marginRight: { xs: "10px", md: "20px" }, fontSize: { xs: "14px", md: "16px" }, '@media (max-width: 500px)': {
                                        fontSize: "13.5px"
                                    }
                                }}>
                                    Inicio
                                </Typography>
                            </Link>
                            <Link to="/productos" style={{ textDecoration: "none", color: "black" }}>
                                <Typography className="plus-jakarta navbar-letra menu__link" sx={{
                                    marginRight: { xs: "10px", md: "20px" }, fontSize: { xs: "14px", md: "16px" }, '@media (max-width: 500px)': {
                                        fontSize: "13.5px"
                                    }
                                }}>
                                    Productos
                                </Typography>
                            </Link>
                            <Link to="/proceso" style={{ textDecoration: "none", color: "black" }}>

                                <Typography className="plus-jakarta navbar-letra menu__link" sx={{
                                    marginRight: { xs: "10px", md: "20px" }, fontSize: { xs: "14px", md: "16px" }, '@media (max-width: 500px)': {
                                        fontSize: "13.5px"
                                    }
                                }}>
                                    Proceso
                                </Typography>
                            </Link>

                            <Link to="/contacto" style={{ textDecoration: "none", color: "black" }}>

                                <Button id="navbar-button" className="button-contacto" >
                                    <Typography className="plus-jakarta navbar-letra" sx={{
                                        fontSize: { xs: "14px", md: "16px" }, '@media (max-width: 500px)': {
                                            fontSize: "13px"
                                        }
                                    }}>

                                        Contáctanos
                                    </Typography>

                                </Button>
                            </Link>

                        </Box>

                    </Box>
                </Box>
            </Container>

        </div >
    );
}

export default NavBar;