import { useRef, useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";

const AboutUs = () => {
    const aboutUsRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const sectionPosition = aboutUsRef.current.offsetTop + aboutUsRef.current.offsetHeight;
            if (scrollPosition > sectionPosition) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Box
            sx={{
                p: 1.5,
                background: "linear-gradient(130deg, #336257 70%, #315A51 70%)",
                borderRadius: "35px",
                position: "relative",
                overflow: "hidden"
            }}        >
            <Box ref={aboutUsRef}
                className={`fade-up-aboutus ${isVisible ? "visible" : ""}`}
                sx={{ marginTop: { xs: "40px", md: "100px" }, textAlign: "center" }}>
                <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: "40px", sm: "50px", md: "60px", lg: "70px" }, color: "white" }}>
                    ¿Quiénes Somos?
                </Typography>
                <Box sx={{ marginTop: { xs: "10px", md: "20px" } }}>
                    <Typography className="plus-jakarta" sx={{ color: "white", maxWidth: "600px", margin: "0 auto" }}>
                        Somos una empresa orientada al desarrollo y fabricación de empaques flexibles, ofreciendo los más altos estándares de calidad en todos nuestros productos y servicios brindados.
                    </Typography>
                </Box>
            </Box>

            <Grid container spacing={3} sx={{ marginTop: { xs: "10px", md: "40px" }, marginBottom: "60px", paddingLeft: "40px", paddingRight: "40px" }}>
                <Grid item xs={12} md={4} className={`fade-left ${isVisible ? "visible" : ""}`}>
                    <Box sx={{ marginTop: { xs: "10px", md: "20px" }, backgroundColor: "#4B8275", borderRadius: "25px", height: "300px" }}>
                        <Typography className="plus-jakarta" sx={{
                            fontSize: {
                                md: "30px", '@media (max-width: 1200px)': {
                                    fontSize: '25px'
                                }, '@media (max-width: 1100px)': {
                                    fontSize: '21px'
                                }
                            }, color: "white", textAlign: "center", paddingTop: "60px"
                        }}>
                            Misión
                        </Typography>
                        <Typography className="plus-jakarta" sx={{
                            fontSize: {
                                md: "15px", '@media (max-width: 1200px)': {
                                    fontSize: '14px'
                                }, '@media (max-width: 1100px)': {
                                    fontSize: '14px'
                                }
                            }, color: "white", padding: "20px", textAlign: "center"
                        }}>
                            Brindamos productos de la más alta calidad diferenciados
                            por la tecnología que contamos, maximizando nuestros
                            procesos para un lead time óptimo.                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} className={`fade-left ${isVisible ? "visible" : ""}`}>
                    <Box sx={{ marginTop: { xs: "10px", md: "20px" }, backgroundColor: "#4B8275", borderRadius: "25px", height: "300px" }}>
                        <Typography className="plus-jakarta" sx={{
                            fontSize: {
                                md: "30px", '@media (max-width: 1200px)': {
                                    fontSize: '25px'
                                }, '@media (max-width: 1100px)': {
                                    fontSize: '21px'
                                }
                            }, color: "white", textAlign: "center", paddingTop: "60px"
                        }}>
                            Visión
                        </Typography>
                        <Typography className="plus-jakarta" sx={{ fontSize: { md: "15px" }, color: "white", padding: "20px", textAlign: "center" }}>
                            Ser la empresa líder en procesos controlados, manteniendo el
                            compromiso con la sociedad y el medio ambiente.                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} className={`fade-left ${isVisible ? "visible" : ""}`}>
                    <Box sx={{ marginTop: { xs: "10px", md: "20px" }, backgroundColor: "#4B8275", borderRadius: "25px", height: "300px" }}>
                        <Typography className="plus-jakarta" sx={{
                            fontSize: {
                                md: "30px", '@media (max-width: 1200px)': {
                                    fontSize: '25px'
                                }, '@media (max-width: 1100px)': {
                                    fontSize: '21px'
                                }
                            }, color: "white", textAlign: "center", paddingTop: "60px"
                        }}>
                            Valores de la empresa
                        </Typography>
                        <Typography className="plus-jakarta" sx={{
                            fontSize: {
                                md: "15px", '@media (max-width: 1200px)': {
                                    fontSize: '14px'
                                }, '@media (max-width: 1100px)': {
                                    fontSize: '14px'
                                }
                            }, color: "white", padding: "20px", textAlign: "center"
                        }}>
                            Los principales valores incluyen honestidad, diferenciación, trabajo en equipo, integridad y responsabilidad social.
                        </Typography>
                    </Box>

                </Grid>
            </Grid>
        </Box >
    );
}

export default AboutUs;
