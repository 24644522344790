import NavBar from "../Components/NavBar"
import Carrusel from "./Components/Carrusel"
import AboutUs from "./Components/AboutUs"
import Tipos from "./Components/Tipos"
import Productos from "./Components/Productos"
import Clientes from "./Components/Clientes"
import End from "./Components/End"
import { Helmet } from 'react-helmet';


import './Inicio.css';
import { Container, Grid, Box, Typography, Button } from "@mui/material";

const Inicio = () => {
    return (
        <div >
            <NavBar />

            <Container maxWidth="xl" sx={{ marginTop: "20px" }}>
                <Helmet>
                    <title>Real Flexo</title>
                    <meta name="description" content="En Real Flexo, somos una empresa peruana orientada al desarrollo y fabricación de empaques flexibles. Nos especializamos en la impresión flexográfica de 10 colores, ofreciendo los más altos estándares de calidad en todos nuestros productos y servicios para satisfacer las necesidades de nuestros clientes." />
                </Helmet>
                <Carrusel />
                <Clientes />
                <AboutUs />
                <Tipos />
                <Productos />



                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
                <a href="https://api.whatsapp.com/send?phone=946678535&text=Hola, me gustaria obtener más información" class="float" target="_blank">
                    <i class="fa fa-whatsapp my-float "></i>
                </a>

                <End />



            </Container>

        </div>

    );

}

export default Inicio;
