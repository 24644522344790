import { Container, Grid, Box, Typography, Button } from "@mui/material";
import React, { useState } from 'react';
import bolsas from "../../Components/Img/Tipos/Bolsas.webp"

const InicioProducto = () => {

    return (


        <Box sx={{ p: 1.5 }}>

            <Box
                sx={{ textAlign: "center" }}>
                <Typography className="plus-jakarta-bold"
                    sx={{
                        fontSize: { xs: "40px", sm: "50px", md: "60px", lg: "70px" },
                        color: "black",
                        maxWidth: "100%",
                        marginBottom: { xs: "30px", md: "50px" },
                        textAlign: { xs: "center" }
                    }}>
                    Productos
                </Typography>

            </Box>

            <Box
                sx={{
                    marginTop: "20px",
                    backgroundColor: "#EFEFEF",
                    background: "linear-gradient(147deg, #F5F5F5 70%, #E6E6E6 70%)",
                    borderRadius: "25px",
                    marginRight: "25px",
                    marginLeft: "25px",
                    height: { xs: "500px", md: "400px" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",

                }}
            >
                <Grid
                    container
                    sx={{
                        marginTop: "40px",
                        marginBottom: "40px",
                        alignItems: "center",
                        justifyContent: "center", // Centra el Grid dentro del Box

                        flexDirection: { xs: "column", md: "row" } // Cambia la dirección del flujo en pantallas pequeñas
                    }}
                >
                    <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", order: { xs: 2, md: 1 } }}>
                        <Typography
                            className="plus-jakarta"
                            sx={{
                                textAlign: { xs: "center", md: "center" }, // Centra el texto en pantallas pequeñas
                                margin: { xs: "20px", md: "20px" },
                                maxWidth: { xs: "300px", md: "500px" },
                                fontSize: { xs: "15px", md: "18px" }, // Ajusta el margen en pantallas pequeñas
                            }}
                        >
                            Contamos con una amplia variedad de estructuras para los procesos en monocapas, bilaminados, trilaminados y tetralaminados dentro de ello contamos una mezcla de varios sustratos. Lo que garantiza una alta calidad en las impresiones y nos permite ofrecer una excelente presentación.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", order: { xs: 1, md: 2 } }}>
                        <img src={bolsas} alt="" style={{ maxWidth: "100%", maxHeight: "auto", width: "65%" }} />
                    </Grid>
                </Grid>
            </Box>

        </Box >

    );
}

export default InicioProducto;
