import { Grid, Box, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const SplineViewer = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/@splinetool/viewer@1.9.6/build/spline-viewer.js';
        script.type = 'module';
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <spline-viewer
            url="https://prod.spline.design/0GaHjROO-0MtD1IE/scene.splinecode"
            id="spline-viewer"
        ></spline-viewer>

    );
};

const Carrusel = () => {
    const [showImage, setShowImage] = useState(false);

    const handleFadeUpComplete = () => {
        setShowImage(true);
    };

    return (


        <Box sx={{ p: 1.5 }}>

            <Grid container>

                <Grid item xs={12} md={6}>
                    <Box className="plus-jakarta-bold fade-up">
                        <Typography
                            className="plus-jakarta-bold"
                            onAnimationEnd={handleFadeUpComplete}
                            sx={{
                                fontSize: { xs: "40px", sm: "50px", md: "60px", lg: "70px" },
                                color: "black",
                                maxWidth: "100%",
                                textAlign: { xs: "center", md: "left" }
                            }}
                        >
                            Descubre
                        </Typography>
                        <Typography
                            className="plus-jakarta-bold"
                            onAnimationEnd={handleFadeUpComplete}
                            sx={{
                                fontSize: { xs: "40px", sm: "50px", md: "60px", lg: "65px" },
                                color: "black",
                                maxWidth: "100%",
                                marginBottom: { xs: "30px", md: "50px" },
                                textAlign: { xs: "center", md: "left" }
                            }}
                        >
                            Nuestras Impresiones
                        </Typography>
                        <Typography
                            className="plus-jakarta"
                            sx={{
                                maxWidth: { xs: "100%", md: "600px" },
                                marginTop: "20px",
                                textAlign: { xs: "center", md: "left" }, marginBottom: { xs: "10px", md: "50px" },
                                fontSize: { xs: "15px", sm: "17px", md: "18px", lg: "18px" },
                            }}
                        >
                            Impresión flexográfica de hasta 10 colores, utilizando tecnología de última generación para obtener resultados impecables en cada proyecto.
                        </Typography>
                    </Box>

                    <Box className="fade-up" sx={{ display: "flex", alignItems: "center", marginTop: "20px", flexDirection: { xs: "column", sm: "row" } }}>
                        <Link to="/contacto" style={{ textDecoration: "none", color: "black" }}>
                            <Button id="navbar-button" className="plus-jakarta button-contacto" sx={{ marginTop: "20px", width: { xs: "100%", sm: "auto" } }}>
                                Ordenar ahora
                            </Button>
                        </Link>
                        <Link to="/productos" style={{ textDecoration: "none", color: "black" }}>
                            <Button id="inicio-button" className="plus-jakarta" sx={{ marginTop: "20px", marginLeft: { xs: 0, sm: "10px" }, width: { xs: "100%", sm: "auto" } }}>
                                <AddIcon />
                            </Button>
                        </Link>
                        <Typography className="plus-jakarta" sx={{ marginLeft: { xs: 0, sm: "10px" }, marginTop: { xs: "10px", sm: "23px" }, textAlign: { xs: "center", sm: "left" } }}>
                            Nuestros productos
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        className="fade-in"
                        sx={{
                            display: "flex",
                            justifyContent: { xs: "center", md: "flex-start" },
                            marginTop: { xs: "20px", md: 0 }
                        }}
                    >

                        <SplineViewer />
                    </Box>
                </Grid>
            </Grid>

        </Box>

    );
}

export default Carrusel;
