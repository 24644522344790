import { Box, Typography, Grid } from "@mui/material";
import logob from "../../Components/Img/logob.webp";
import smallLogob from "../../Components/Img/smallLogob.webp";

import PhoneIcon from '@mui/icons-material/Phone';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { Link } from 'react-router-dom';

const End = () => {

    return (
        <Box sx={{ p: 1.5, backgroundColor: "#0a0a0a", borderTopLeftRadius: "20px", borderTopRightRadius: "20px", marginTop: "60px" }}>
            <Box sx={{ height: "120px", width: "auto", backgroundColor: "#141515", alignItems: "center", padding: "30px 20px", borderRadius: "20px", margin: "10px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "5px" }}>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <img src={logob} alt="Logo" style={{ height: "50px", width: "auto" }} />
                    </Box>

                    {/* Logo para pantallas pequeñas */}
                    <Box sx={{ display: { xs: 'block', md: 'none' }, marginRight: "5px" }}>
                        <img src={smallLogob} alt="Logo" style={{ height: "50px", width: "auto" }} />
                    </Box>
                    <Box>
                        <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                            <Grid item>
                                <a href="tel:+51946678535" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <PhoneIcon sx={{ fontSize: '28px', marginRight: { xs: '5px', md: 'none', color: "white" } }} />
                                </a>
                            </Grid>
                            <Grid item sx={{ display: { xs: 'none', md: 'block', marginRight: "10px" } }}>
                                <a href="tel:+51946678535" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Typography className="plus-jakarta-bold" sx={{ fontSize: '14px', fontWeight: 'bold', color: "white" }}>
                                        (+51) 946-678-535
                                    </Typography>
                                </a>
                            </Grid>
                            <Grid item>
                                <a href="mailto:ventas@realflexo.com.pe" style={{ textDecoration: 'none', color: 'inherit', color: "white" }}>

                                    <MarkunreadIcon sx={{ fontSize: '28px', marginRight: { xs: '5px', md: 'none' } }} />
                                </a>
                            </Grid>
                            <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                                <a href="mailto:ventas@realflexo.com.pe" style={{ textDecoration: 'none', color: 'inherit' }}>

                                    <Typography className="plus-jakarta-bold" sx={{ fontSize: '15px', fontWeight: 'bold', color: "white" }}>ventas@realflexo.com.pe</Typography>
                                </a>

                            </Grid>
                        </Grid>
                    </Box>


                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                            <Typography className="plus-jakarta" sx={{ marginRight: { xs: "10px", md: "20px" }, fontSize: { xs: "14px", md: "16px" }, color: "white" }}>
                                Inicio
                            </Typography>
                        </Link>
                        <Link to="/productos" style={{ textDecoration: "none", color: "white" }}>
                            <Typography className="plus-jakarta" sx={{ marginRight: { xs: "10px", md: "20px" }, fontSize: { xs: "14px", md: "16px" }, color: "white" }}>
                                Productos
                            </Typography>
                        </Link>
                        <Link to="/proceso" style={{ textDecoration: "none", color: "white" }}>

                            <Typography className="plus-jakarta" sx={{ marginRight: { xs: "10px", md: "20px" }, fontSize: { xs: "14px", md: "16px" }, color: "white" }}>
                                Proceso
                            </Typography>
                        </Link>


                    </Box>

                </Box>

            </Box>
            <Box sx={{ height: "50px", width: "auto", alignItems: "center", padding: "30px 20px", borderRadius: "20px", margin: "10px" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                    <Typography className="plus-jakarta" sx={{ color: "white" }}>
                        Copyright © RealFlexo
                    </Typography>

                </Box>

            </Box>


        </Box>
    );
}

export default End;
