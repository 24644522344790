import { Box, Typography, Grid } from "@mui/material";
import maquina from "../../Components/Img/maquina.webp"
import bolsas from "../../Components/Img/Tipos/Bolsas.webp"
import etiquetas from "../../Components/Img/Tipos/Etiquetas.webp"
import lineacomercial from "../../Components/Img/Tipos/LineaComercial.webp"

import React, { useState } from 'react';

const Tipos = () => {
    const [showDescription1, setShowDescription1] = useState(false);
    const [showDescription2, setShowDescription2] = useState(false);
    const [showDescription3, setShowDescription3] = useState(false);

    return (
        <Box sx={{}}>
            <Box sx={{ marginTop: "80px", textAlign: "center" }}>
                <Typography className="plus-jakarta-bold" sx={{ fontSize: { xs: "40px", sm: "50px", md: "60px", lg: "70px" }, color: "black" }} >
                    ¿Qué Ofrecemos?
                </Typography>
                <Box sx={{ marginTop: "20px" }}>
                    <Typography className="plus-jakarta" sx={{ color: "black", maxWidth: "600px", margin: "0 auto" }}>
                        Impresión flexográfica de alta calidad, con tecnología avanzada y diseños personalizados.
                    </Typography>
                </Box>
            </Box>

            <Grid container spacing={2} sx={{ marginTop: { xs: "10px", md: "40px" }, marginBottom: "60px" }}>
                <Grid item md={6} xs={12}>
                    <Box sx={{
                        marginTop: { xs: "10px", md: "20px" },
                        backgroundColor: "#FCFCFC", borderRadius: "25px", height: { md: "945px", xs: "745px" },
                        overflow: "hidden",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                        '@media (max-width: 500px)': {
                            height: "650px"
                        },
                        '@media (max-width: 400px)': {
                            height: "600px"
                        }
                    }}>
                        <Typography className="plus-jakarta" sx={{ fontSize: "30px", textAlign: "center", paddingTop: "60px" }}>
                            Calidad
                        </Typography>
                        <Typography className="plus-jakarta" sx={{ padding: "20px", textAlign: "center" }}>
                            Utilizando la más avanzada maquinaria para producción flexográfica.
                        </Typography>
                        <Box sx={{
                            marginTop: { xs: "20px", xl: "0px" }, display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <img
                                src={maquina}
                                alt="Maquina Impresora RealFlexo"
                                style={{
                                    width: "70%",
                                    height: "auto"
                                }}
                            />

                        </Box>
                        <Typography
                            id="btn-shine"
                            className="plus-jakarta"
                            sx={{
                                padding: '20px',
                                textAlign: 'center',
                                fontSize: { xs: "50px", sm: "50px", md: "60px", lg: "70px" },

                                background: 'linear-gradient(90deg, red, red, yellow, green, blue, indigo, violet)',
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                        >
                            10 colores
                        </Typography>
                    </Box>
                </Grid>

                <Grid item md={6} xs={12} >
                    <Box
                        sx={{
                            marginTop: "20px",
                            backgroundColor: "#FCFCFC",
                            borderRadius: "25px",
                            height: "300px",
                            position: "relative",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                        }}
                        style={{ transitionDelay: showDescription1 ? "0.5s" : "0s" }}
                        onMouseEnter={() => setShowDescription1(true)}
                        onMouseLeave={() => setShowDescription1(false)}
                    >
                        <Typography className="plus-jakarta" sx={{ fontSize: "30px", textAlign: "center", paddingTop: "15px" }}>
                            Bolsas
                        </Typography>
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                visibility: showDescription1 ? "visible" : "hidden",
                                transition: "visibility 0s, opacity 0.3s",
                                borderRadius: "25px",

                            }}
                        >
                            <Typography className="plus-jakarta" sx={{ padding: "20px", textAlign: "center" }}>
                                Realizamos bolsas con Sello lateral, Sello fondo, Sello U, Sello T (ALETA), Sello H, Sello Pouch, Zipper y Doypack.
                            </Typography>
                        </Box>
                        <Box sx={{

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                        }}>
                            <img
                                src={bolsas}
                                alt="Bolsas"
                                style={{
                                    maxWidth: "290px",
                                    height: "auto",
                                    transform: showDescription1 ? "scale(0)" : "scale(1)",
                                    transition: "transform 0.3s",
                                    marginTop: "20px"
                                }}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            marginTop: "20px",

                            borderRadius: "25px",
                            height: "300px",
                            position: "relative",
                            overflow: "hidden",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)", // Sombra para dar profundidad
                            backgroundColor: "#FCFCFC",
                        }}
                        style={{ transitionDelay: showDescription2 ? "0.5s" : "0s" }}
                        onMouseEnter={() => setShowDescription2(true)}
                        onMouseLeave={() => setShowDescription2(false)}
                    >
                        <Typography className="plus-jakarta" sx={{ fontSize: "30px", textAlign: "center", paddingTop: "15px" }}>
                            Etiquetas
                        </Typography>
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                visibility: showDescription2 ? "visible" : "hidden",
                                transition: "visibility 0s, opacity 0.3s",
                                borderRadius: "25px",
                            }}
                        >
                            <Typography className="plus-jakarta" sx={{ padding: "20px", textAlign: "center" }}>
                                Realizamos etiquetas para todo tipo de envases.
                            </Typography>
                        </Box>
                        <Box sx={{

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",

                        }}>
                            <img
                                src={etiquetas}
                                alt="Laminados"
                                style={{
                                    maxWidth: "290px",
                                    height: "auto",
                                    transform: showDescription2 ? "scale(0)" : "scale(1)",
                                    transition: "transform 0.3s",
                                }}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            marginTop: "20px",

                            borderRadius: "25px",
                            height: "300px",
                            position: "relative",
                            overflow: "hidden",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                            backgroundColor: "#FCFCFC",
                        }}
                        style={{ transitionDelay: showDescription3 ? "0.5s" : "0s" }}
                        onMouseEnter={() => setShowDescription3(true)}
                        onMouseLeave={() => setShowDescription3(false)}
                    >
                        <Typography className="plus-jakarta" sx={{ fontSize: "30px", textAlign: "center", paddingTop: "15px" }}>
                            Línea comercial
                        </Typography>
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                visibility: showDescription3 ? "visible" : "hidden",
                                transition: "visibility 0s, opacity 0.3s",
                                borderRadius: "25px",
                            }}
                        >
                            <Typography className="plus-jakarta" sx={{ padding: "20px", textAlign: "center" }}>
                                Realizamos bolsas para Papel Higiénico y bolsas de polipropileno.
                            </Typography>
                        </Box>
                        <Box sx={{

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                        }}>
                            <img
                                src={lineacomercial}
                                alt="Otro Tipo"
                                style={{
                                    maxWidth: "290px",
                                    height: "auto",
                                    transform: showDescription3 ? "scale(0)" : "scale(1)",
                                    transition: "transform 0.3s",
                                    marginTop: "20px"

                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    );
}

export default Tipos;
